import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'

import { createHead } from '@vueuse/head'


const lang = 'fr';
const head = createHead()

// Créez l'application Vue et configurez les propriétés globales
const app = createApp(App);

/*
createApp(App).config.globalProperties.$lang = lang;
createApp(App).use(head).use(router,bootstrap)
.mount('#app'); */

// Configurez les propriétés globales de l'application
app.config.globalProperties.$lang = lang;

// Utilisez l'en-tête et le router dans l'application Vue
app.use(head).use(router, bootstrap)

// Montez l'application Vue sur l'élément avec l'ID 'app'
app.mount('#app')

// Modifiez l'attribut lang de l'élément <html>
document.documentElement.lang = lang;