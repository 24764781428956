<template>
    <HeaderComponent/>
<div class="container mt-5  mb-5">
  <div class="row mb-5 ">
    <div class="col-lg-6">
      <h1>Tsc applications</h1>
    </div>
    <div>
    <div class="col-lg-5">
      <div class="input-group">
        <input type="text" v-model="searchTerm" @input="searchByName" class="form-control form-control-sm search-input"
        id="searchId" autocomplete="off" placeholder="Rechercher par nom de l'application" style="width: 50%;">
        <div class="input-group-append">
          <span class="input-group-text"><i class="fas fa-search"></i></span>
        </div>
      </div>
    </div>
  </div>

  </div>
  <!-- Ligne de cartes -->
  <div class="row justify-content-center">
    <div v-for="(card, index) in displayedCards" :key="index" class="col-md-4 mb-4">
      <!-- Carte -->
      <div class="card mx-auto h-100">
        <!-- Image de la carte -->
        <img rel="preload" :src="card.image" class="card-img-top rounded-circle mx-auto mt-3" alt="Image de la carte" as="image"
          style="width: 150px; height: 150px;">
        <div class="card-body w-500">
          <!-- Titre de la carte -->
          <h5 class="card-title text-center no-underline">{{ card.title }}</h5>
          <!-- Description de la carte -->
          <p class="card-text text-center">{{ truncateDescription(card.description )}}</p>
          <!-- Bouton "En savoir plus" -->
          <div class="text-center">
            
            <router-link v-if="card.buttonLink" :to="{ name: 'app.DetailPage', params: { id: card.id }, query: { data: card } }" class="btn btn-primary">En savoir plus</router-link>
            <!--
            <a href="#" class="btn btn-primary">En savoir plus</a>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Bouton "Afficher plus" -->
  <div v-if="displayedCards.length < cards.length">
    <button @click="showMoreCards" class="btn btn-primary">Afficher plus d'applications</button>
  </div>
  <div class="col-lg-6 p-3 text-content-left">
    <div class="mb-5">
      <p> * Toutes les applications doivent être multiparamétrées selon le secteur d'activité.</p>
      <p> * Une année de garantie est offerte pour toutes les applications.</p>
    </div>
  </div>
  
  </div>
  <FooterComponent />
</template>

<script>
import HeaderComponent from '../HeaderComponent.vue'
import FooterComponent from '../FooterComponent.vue';

export default {
  name: 'BodyPageComponent',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  head() {
    return {
      title: 'TSC applications', // Titre de la page
      meta: [
        { hid: 'description', name: 'description', content: 'Tunisian Software Challenge Applications applications page.' }, // Balise META description
        { hid: 'keywords', name: 'keywords', content: 'tsc, applications, tsc applications, tunisian software challenge, tunisian software challenge applications' } // Balise META keywords
        // Vous pouvez ajouter d'autres balises META ici
      ]
    }
  },
  data() {
    return {
      searchTerm: '', // Terme de recherche saisi par l'utilisateur
      cards: [
        {
          image: "assets/images/applications/stock.webp",
          title: "Stock",
          description: "Description brève de la carte 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/commerciale.webp",
          title: "Gestion commerciale",
          description: "Description brève de la carte 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications'
        },
        {
          image: "assets/images/applications/personnel.webp",
          title: "Paie du personnel",
          description: "Description brève de la carte 3. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/comptabilite.webp",
          title: "Comptabilité",
          description: "Description brève de la carte 4. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/pointeuse.webp",
          title: "Pointeuse",
          description: "Description brève de la carte 5. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/immobilisation.webp",
          title: "Gestion des immobilisations",
          description: "Description brève de la carte 6. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/production.webp",
          title: "Gestion de Production",
          description: "Description brève de la carte 7. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/marketing.webp",
          title: "Projets de marketing",
          description: "Description brève de la carte 16. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/reglement.webp",
          title: "SUM des Règlement",
          description: "Description brève de la carte 8. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/depot.webp",
          title: "Gestion des dépôts",
          description: "Description brève de la carte 9. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/caisse.webp",
          title: "Gestion de la caisse",
          description: "Description brève de la carte 10. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/competence.webp",
          title: "Gestion des Compétences",
          description: "Description brève de la carte 11. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/scolaire.webp",
          title: "Gestion scolaire (Université)",
          description: "Description brève de la carte 13. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/scolaire.webp",
          title: "Gestion scolaire (lycées)",
          description: "Description brève de la carte 14. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        },
        {
          image: "assets/images/applications/scolaire.webp",
          title: "Gestion scolaire (collèges)",
          description: "Description brève de la carte 15. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          buttonLink: '/applications',
        }
      ],
      displayedCards: [],
      cardsPerPage: 6,
      currentIndex: 0
    };
  },
  computed: {
    filteredCards() {
      // Filtrer les cartes en fonction du terme de recherche
      return this.cards.filter(card => {
        return card.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          card.description.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    }
  },
  methods: {
    truncateDescription(description) {
      return description.substring(0, 50) + '...'; // Tronquer la description à 50 caractères et ajouter des points de suspension
    },
    showMoreCards() {
      const newIndex = this.currentIndex + this.cardsPerPage;
      this.displayedCards = this.cards.slice(0, newIndex);
      this.currentIndex = newIndex;
    },
    searchByName() {
      // Réinitialiser la pagination
      this.currentIndex = 0;

      // Filtrer les cartes par nom
      this.displayedCards = this.cards.filter(card => {
        return card.title.toLowerCase().includes(this.searchTerm.toLowerCase());
      });

      // Vérifier s'il n'y a pas d'applications trouvées
      if (this.displayedCards.length === 0) {
        // Modifier le contenu de displayedCards pour afficher le message approprié
        this.displayedCards = [{
          image: "assets/icons/error.webp",
          title: "Aucune application trouvée",
          description: "Aucune application ne correspond à votre recherche.",
          //buttonLink: '#',
        }];
      }
    },
  },
  created() {
    this.showMoreCards();
  },

}
</script>

<style>

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Pour répartir l'espace entre les cartes */
}

.card {
  /* Styles de votre carte */
  width: calc(100% - 10px);
  /* Largeur de chaque carte (moins les marges) pour 3 cartes par ligne */
  margin-bottom: 80px;
  /* Marge inférieure pour l'espacement vertical */
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  padding: 0;
  cursor: pointer;
}

.btn-color {
  color: white !important;
  background: #4ad7d1 !important;
}

.text-content {
  font-size: larger;
  word-spacing: 0.3em;
}

.main {
  /* min-height:100vh; */
  padding: 5%;
  margin: 0%;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
}

.card-img {
  width: 400px;
  height: 100%;
  /* Assurez-vous que l'image remplit entièrement le conteneur */
  object-fit: cover;
  border-radius: 15px;
  /* Choisissez le rayon de la courbure selon vos préférences */
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.card-title {
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #4ad7d1;
}

.text-content-left {
  text-align: left;
  margin-top: 50px;
  margin-left: 100px;
}

.no-underline {
  text-decoration: none;
  /* Supprime le soulignement */
}

.search-input {
  font-size: 0.8rem;
  /* Réduire la taille de la police */
  padding: 0.25rem 0.5rem;
  /* Réduire le remplissage haut/bas et gauche/droite */
  height: auto;
  /* Ajuster la hauteur automatiquement en fonction de la taille du contenu */
  width: auto;
  /* Ajuster la largeur automatiquement en fonction de la taille du contenu */
}
</style>
