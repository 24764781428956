<template>
  <div>
    <BodyHomeComponent />
  </div>
</template>

<script>
import BodyHomeComponent from '../Body/BodyHomeComponent.vue';
export default {
  name: 'app_home',
  components: {
    BodyHomeComponent,
  },
  metaInfo: {
    title: 'Page d\'accueil - TSC',
    meta: [
      { hid: 'description', name: 'description', content: 'Tunisian Software Challenge Applications home page.' },// Balise META description
      {  hid: 'keywords', name: 'keywords', content: 'tsc, applications, tsc applications, tunisian software challenge' }// Balise META keywords
              // Vous pouvez ajouter d'autres balises META ici
    ]
  }
      
}
</script>
<style></style>