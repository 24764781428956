<template>
  <div>
    <BodyAboutComponent />
  </div>
</template>
<script>
import BodyAboutComponent from '../Body/BodyAboutComponent.vue';
export default {
  name: 'app_aboutus',
  components: {
    BodyAboutComponent,
  }
}
</script>
<style></style>