<template>
  <div>
    <HeaderComponent />

    <div class="row  main align-items-center">
      <div class="col-lg-6">
        <img rel="preload" as="image" src="/assets/images/contact.webp" class="card-img img-fluid rounded justify-content-center"
          alt="..." style="width: 100%; height: 100%;" />
      </div>
      <div class="col-lg-6 p-3 ">
        <div class="text-content-center mb-6">
          <h1 class="text-content large-text"> Contact TSC</h1>
          <div>
            <p class="text-content">
              Mail: contact@tunisiansc.tn
            </p>
            <p class="text-content">
              Téléphone: +216 51 884 013 
            </p>
            <p class="text-content">
              Site web: www.tunisiansc.tn
            </p>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import HeaderComponent from '../HeaderComponent.vue'
import FooterComponent from '../FooterComponent.vue';
export default {
  name: 'BodyHomeComponent',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  head() {
    return {
      title: 'TSC contact', // Titre de la page
      meta: [
        { hid: 'description', name: 'description', content: 'Tunisian Software Challenge Applications contact page.' }, // Balise META description
        { hid: 'keywords', name: 'keywords', content: 'tsc, contact, tsc contact, tunisian software challenge, tunisian software challenge contact, 51884013, contact@tunisiansc.tn, www.tunisiansc.tn' } // Balise META keywords
        // Vous pouvez ajouter d'autres balises META ici
      ]
    }
  }
}
</script>

<style>
#p {
  text-align: left;
}

.text-content-center {
  margin-top: 50px;
  text-align: center;
}

.large-text {
  font-size: px;
}
</style>
