<template>
  <HeaderComponent />
  <h1>TSC Application</h1>
  <footer-component />
</template>

<script>
import HeaderComponent from '../HeaderComponent.vue'
import FooterComponent from '../FooterComponent.vue';
export default {
  name: 'BodyHomeComponent',
    components: {
        HeaderComponent,
        FooterComponent,
    },
  head() {
    return {
      title: 'TSC', // Titre de la page
      meta: [
        { hid: 'description', name: 'description', content: 'Tunisian Software Challenge Application page.' }, // Balise META description
        { hid: 'keywords', name: 'keywords', content: 'tsc, applications, tsc applications, tunisian software challenge applications, app tsc' } // Balise META keywords
        // Vous pouvez ajouter d'autres balises META ici
      ]
    }
  }
}
</script>

<style>

</style>