<template>
  <div id="amir">
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
      <router-link to="/">
        <img rel="preload" as="image" id="logo" src="/assets/images/logo.webp" alt="Logo de l'entité de la page" /></router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="container">

        <div class="collapse navbar-collapse mx-auto" id="navbarSupportedContent">
          <!-- Left Side Of Navbar -->
          <ul class="navbar-nav me-auto">
            <!--menu pour monopage-->
            
              <li :class="{ 'active': $route.path === '/' }" class="nav-item">
              <router-link class="nav-link" aria-current="app_home" to="/" >Accueil</router-link>
            </li>
            <li :class="{ 'active': $route.path === '/aboutus' }" class="nav-item">
              <router-link class="nav-link" aria-current="app_aboutus" to="/aboutus">À propos</router-link>
            </li>
            <li :class="{ 'active': $route.path === '/applications' }" class="nav-item">
              <router-link class="nav-link" aria-current="app_applications" to="/applications">Applications</router-link>
            </li>
            <li :class="{ 'active': $route.path === '/contact' }" class="nav-item">
              <router-link class="nav-link" aria-current="app_contact" to="/contact">Contact</router-link>
            </li>
           
            <!--menu pour multiepage-->
            <!--
            <li :class="{ 'active': $route.path === '/' }" class="nav-item">
              <a class="nav-link" aria-current="app_home" href="/" >Accueil</a>
            </li>
            <li :class="{ 'active': $route.path === '/aboutus' }" class="nav-item">
              <a class="nav-link" aria-current="app_aboutus" href="/aboutus">À propos</a>
            </li>
            <li :class="{ 'active': $route.path === '/applications' }" class="nav-item">
              <a class="nav-link" aria-current="app_applications" href="/applications">Applications</a>
            </li>
            <li :class="{ 'active': $route.path === '/contact' }" class="nav-item">
              <a class="nav-link" aria-current="app_contact" href="/contact">Contact</a>
            </li>
             -->
          </ul>

          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ms-auto">
            <li></li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "HeaderComponent",
};
</script>

<style >

.navbar {
  top: 5px;
  padding: 1% 15%;
  z-index: 100; /* Pour s'assurer que le HeaderComponent est au-dessus du contenu de la page */
  /*position: fixed; */
  width: 95%;
  margin-left: 30px;

}

#logo { 
  margin: 3px 10px;
  height: 80px;
  width: 100;
}



.collapse ul {
  text-align: center;
  margin-left: 25%;
}

.nav-item.active {
  font-size: 20px; /* Taille du texte */
  color: #1877f2 !important; /* Couleur du texte (rouge) */
}
</style>
