import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Pages/Home.vue";
import Contact from "@/components/Pages/Contact.vue";
import About from "@/components/Pages/Aboutus.vue";
import Applications from '@/components/Pages/Applications.vue';
import DetailComponent from '@/components/Pages/DetailComponent.vue';

const routes = [
  {
    path: "/",
    name: "app.home",
    component: Home,
    meta: { title: "Accueil" },
  },
  {
    path: "/contact",
    name: "app.contact",
    component: Contact,
    meta: { title: "Contact" },
  },
  {
    path: "/aboutus",
    name: "app.about",
    component: About,
    meta: { title: "À propos" },
  },
  {
    path: '/applications',
    name: 'app.applications',
    component: Applications,
    meta: { title: 'Applications' }
  },
  {
    path: '/details',
    name: 'app.DetailPage',
    component: DetailComponent,
    meta: { title: 'Details' }
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
    //rerirection en haut de la page.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // Si une position de défilement précédente est enregistrée, revenez à cette position
      return savedPosition;
    } else {
      // Sinon, faites défiler jusqu'en haut de la nouvelle page
      return { top: 0 };
    }
  }
});
// Mettre à jour le titre de la page en fonction de la route
router.beforeEach((to, from, next) => {
  document.title = `TSC ${to.meta.title}`;
  next();
});
export default router;
