
<template> 
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import {computed, reactive} from 'vue'
import {useHead } from '@vueuse/head'
export default ({
  setup(){
    const  siteData =reactive({
      title: `Tunisian software challenge`,
      description: `Tunisian software challenge website` })
      useHead({
        title : computed(()=> siteData.title),
        meta:[
          {
            name: `description`,
            content: computed(()=> siteData.description),
          },

          { hid: 'tsc',
            name: 'tsc',
            content: 'tsc, applications, tsc applications, tunisian software challenge'
          },
          { hid: 'keywords',
            name: 'keywords',
            content: 'tsc, applications, tsc applications, tunisian software challenge'
}

        ],
      })
  },
});
</script>
<style>
#app {
  position: relative;
}

</style>
<link rel="icon" href="/logo.webp" type="image/webp">
