<template>
  <div>
    <HeaderComponent />
    <div class="text-content-center">
      <img src="/assets/images/logo.png" class="left-image card-img img-fluid rounded justify-content-center" 
      alt="..." style="width: 25%; height: 25%;" />
    </div>
    <div class="block-text">
      <h1 id="titre">Présentation TSC:</h1>

      <p>
        TEXT 1
      </p>
      <h1 id="titre">
        L'histoire de TSC
      </h1>
      <p>
        TEXT 2
      </p>
      <h1 id="titre">
        L'activité, des produits et des services de TSC
      </h1>
      <p>
        TEXT 3
      </p>
      <h1 id="titre">
        L'équipe TSC
      </h1>
      <p>
        TEXT 4
      </p>
      <h1 id="titre">
        Joindre TSC
      </h1>
      <p>
        TEXT 5
      </p>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '../HeaderComponent.vue'
import FooterComponent from '../FooterComponent.vue';
export default {
  name: 'BodyHomeComponent',
  components: {
    HeaderComponent,
    FooterComponent,
  }
}
</script>

<style>
.text-content-left {
  text-align: left;
  margin-top: 50px;
  margin-left: 0px;
}

.left-image {
  width: 200px;
  margin-right: 20px;
}

.block-text {
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  text-align: left;
}

#titre {
  font-size: 24px;
  font-weight: bold;
  color: #1877f2;
}
</style>