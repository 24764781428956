<template>
    <div>
        <HeaderComponent />
        <div class="row  main align-items-center">
            <div class="col-lg-6">
                <img rel="preload" as="image" src="/assets/images/home.webp" class="card-img img-fluid rounded justify-content-center" alt="..."
                    style="width: 100%; height: 100%;" />
            </div>
            <div class="col-lg-6 p-3 ">
                <div class="mb-5">
                        <h1 class="text-content large-text">Tunisian Software Challenge</h1>
                    <p class="text-content">
                        Développement des solutions informatiques
                    </p>
                </div>
                <div class="d-grid col-6 mx-auto">
                    <router-link to="/contact" class="button-link">
                        <button class="btn btn-lg" type="button">Contactez-nous</button>
                    </router-link>
                </div>
            </div>
        </div>
        <FooterComponent />
    </div>
</template>

<script >
import HeaderComponent from '../HeaderComponent.vue'
import FooterComponent from '../FooterComponent.vue';
export default {
    name: 'BodyHomeComponent',
    components: {
        HeaderComponent,
        FooterComponent,
    },
    metaInfo: {
      title: 'TSC', // Titre de la page
      meta: [
        { hid: 'description', name: 'description', content: 'Tunisian Software Challenge home page.' }, // Balise META description
        { hid: 'keywords', name: 'keywords', content: 'tsc, applications, tsc applications, tunisian software challenge, tunisian software challenge applications, 51884013, contact@tunisiansc.tn, www.tunisiansc.tn' } // Balise META keywords
        // Vous pouvez ajouter d'autres balises META ici
      ]
    }
}
</script>

<style>

.main {
    padding: 5%;
    margin: 0%;
    width: 100%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #31502c;
    margin-top: 60px;
}

.text-content {
    font-size: larger;
    word-spacing: 0.3em;
}

.main {
    padding: 5%;
    margin: 0%;
    width: 100%;
}

.large-text {
    font-size: 35px;
}



.button-link {  
    color: #e32222; 
  /* Styles pour le lien */
}

.btn {
  color: #fff; /* Couleur du texte */
  background-color: #007bff; /* Couleur de fond */
  border-color: #007bff; /* Couleur de la bordure */
  /* Autres styles au besoin */
}

.btn:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
  border-color: #0056b3; /* Couleur de la bordure au survol */
}
</style>