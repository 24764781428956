<template>
  <div>
    <ContactHomeComponent />
  </div>
</template>
<script>
import ContactHomeComponent from '../Body/BodyContactComponent.vue';
export default {
  name: 'app_contact',
  components: {
    ContactHomeComponent,
  },
  head() {
    return {
      title: 'TSC contact', // Titre de la page
      meta: [
        { hid: 'description', name: 'description', content: 'Tunisian Software Challenge Applications contact page.' }, // Balise META description
        { hid: 'keywords', name: 'keywords', content: 'tsc, contact, tsc contact, tunisian software challenge, tunisian software challenge contact' } // Balise META keywords
        // Vous pouvez ajouter d'autres balises META ici
      ]
    }
  }
}
</script>
<style></style>