<template>
    <div>
      <BodyApplicationsComponent/> 
    </div>
</template>
<script>
import BodyApplicationsComponent from '../Body/BodyApplicationsComponent.vue';
export default {
  name:'app_applications',
  components: {
    BodyApplicationsComponent,
  },
  head() {
    return {
      title: 'TSC applications', // Titre de la page
      meta: [
        { hid: 'description', name: 'description', content: 'Tunisian Software Challenge Applications home page.' }, // Balise META description
        { hid: 'keywords', name: 'keywords', content: 'tsc, applications, tsc applications, tunisian software challenge' } // Balise META keywords
        // Vous pouvez ajouter d'autres balises META ici
      ]
    }
  }
}
</script>
<style>
</style>